import React from 'react';

import AppChrome from '../components/AppChrome';
import TopNav from '../components/TopNav';
import ContentWrapper from '../components/reusables/ContentWrapper';

function Policy() {
  return (
    <AppChrome
      title="Integritetspolicy"
      // backgroundColor="#000000"
      og={{
        title: 'Förändrar vi beteenden förändrar vi världen',
        description: `
          Våra lösningar har fokus på att uppnå affärsnytta med positiv
          förändring för både planeten och individen. Insikterna skapar
          förståelse för beteenden och attityder som blir bränsle till att
          designa innovativa lösningar som testas genom experimentell metod.
        `,
      }}
    >
      <TopNav />
      <ContentWrapper>
        <section>
          <div>
            <article>
              <div>
                <h1>Integritetspolicy</h1>
              </div>
              <div>
                <h3>Syfte med vår integritetspolicy</h3>
                <p>
                  Syftet med detta dokument är att förtydliga hur Beteendelabbet
                  AB sköter hanteringen av era personuppgifter, vad de används
                  till, varför de lagras, och hur länge. Detta dokument visar
                  även på vilka grunder ett bolag har rätt att hantera dina
                  personuppgifter och hur du tar kontakt för att utöva någon av
                  dina rättigheter som rör hanteringen.
                </p>
                <h3>Allmänt gällande insamling av data</h3>
                <p>
                  Dina personuppgifter behandlas inom Beteendelabbet AB enligt
                  Dataskyddsförordningen (GDPR). Den personinformation som
                  samlas in är uppgifter som mottagits från personer som bolaget
                  har haft, har, eller önskar ha, ett samarbete med, så som
                  kunder, investerare, eller personer i nätverket i övrigt.
                </p>
                <p>Förtydligande av definitioner</p>
                <p>
                  <em>Personuppgiftsbiträde</em>
                  <br />
                  För att en verksamhet ska kunna vara välfungerande finns flera
                  situationer där det kan finnas ett behov av att lämna vidare
                  personinformation till en partner, eller tredje part. Dessa
                  kallas då för Personuppgiftsbiträde. Deras hantering av
                  uppgifter regleras av ett separat avtal mellan Beteendelabbet
                  AB och personuppgiftsbiträdet.
                </p>
                <p>
                  <em>Personuppgiftsansvarig</em>
                  <br />
                  Det företag som utfört registrering av persondata och
                  bestämmer syftet med behandlingen av personuppgifter och hur
                  behandlingen ska gå till är enligt förordningen
                  Personuppgiftsansvarig. Beteendelabbet AB står alltså ytterst
                  ansvariga för hanteringen av de personuppgifter som bolaget
                  samlat in.
                </p>
                <h3>Typ av data som samlas in och hur den används</h3>
                <ul>
                  <li>
                    Data med avsikt att föra statistik över hemsida och
                    marknadsföring lagras uppgifter om IP adresser, plats och
                    annat som den som surfar in gett tillåtelse till via
                    personliga browser inställningar.
                  </li>
                  <li>
                    Data gällande kunder, samarbetspartners, investerare och
                    övrigt nätverk: Personspecifik information om kunder,
                    samarbetspartners och övriga kontakter i mitt nätverk samlas
                    in och sparas för att kunna ha en fungerande
                    konsultverksamhet, sköta kommunikation, offerera och skriva
                    avtal, fakturera, samt för att vidareutveckla bolagets
                    nätverk och inför potentiellt framtida uppdrag. Den typen av
                    data är tex namn, personnummer (i de fall detta utgör
                    organisationsnummer), telefon, epost, bolagstillhörighet,
                    roll, adress (oftast i de fall hemadress utgör ett bolags
                    adress).
                  </li>
                </ul>
                <h3>Typ av persondata som inte samlas in</h3>
                <p>
                  Beteendelabbet AB samlar inte in denna typ av information då
                  den anses som känsliga enligt förordningen och inte fyller
                  någon funktion för verksamheten:
                </p>
                <ul>
                  <li>ras eller etniskt ursprung</li>
                  <li>politiska åsikter</li>
                  <li>religiös eller filosofisk övertygelse</li>
                  <li>medlemskap i en fackförening</li>
                  <li>en persons sexualliv eller sexuella läggning</li>
                  <li>genetiska uppgifter och</li>
                  <li>
                    biometriska uppgifter som entydigt identifierar en person.
                  </li>
                </ul>
                <h3>Hur persondata samlas in</h3>
                <p>
                  Persondata samlas in genom personliga möten och via nätverk.
                  <br />
                  Persondata samlas även in då personer surfar in på hemsidan
                  eller någon av de webbsidor som är kopplade till bolaget,
                  själv anger sin information via webbformulär på hemsidan,
                  eller tar kontakt via mail, telefon eller sociala medier,
                  direkt till mig eller via någon av de produkter som erbjuds
                  genom Beteendelabbet AB.
                </p>
                <h3>När persondata får samlas in</h3>
                <p>
                  Det måste enligt lagstiftningen finnas en så kallad ”rättslig
                  grund” för att bolag ska få hantera personuppgifter. Det
                  betyder att minst ett av följande villkor måste vara uppfyllt
                  för att dina personuppgifter ska få hanteras:
                </p>
                <p>
                  <em>Samtycke</em>
                  <br />
                  Du kan också lämna ditt samtycke till hantering av dina
                  personuppgifter. För att ett samtycke ska bli giltigt krävs
                  att du gör en aktiv handling för att godkänna samtycket.
                  Exempel på en sådan aktiv handling kan vara att du via mail
                  ber om att få information, att du svarar ja på en direkt fråga
                  om att information ska skickas till dig, att du ger ut ditt
                  visitkort eller liknande. I förväg ikryssade ”Jag godkänner”
                  rutor accepteras inte som samtycke. Ett samtycke ska förutom
                  att vara aktivt även vara frivilligt, specifikt, informerat
                  och tydligt. Du kan alltid, när som helst, dra tillbaka ett
                  samtycke.
                </p>
                <p>
                  <em>Rättslig förpliktelse</em>
                  <br />
                  Vissa uppgifter får sparas för att fullgöra en rättslig
                  förpliktelse. Det gäller exempelvis uppgifter som behövs för
                  att uppfylla bokföringslagen och liknande som gäller som ett
                  undantag för, och är överordnad, Dataskyddsförordningen. I
                  fallet om bokföringslagen finns också bestämmelser om hur
                  länge uppgifterna enligt lag måste sparas.
                </p>
                <p>
                  <em>Avtal</em>
                  <br />
                  Då uppgifter behövs för att sluta och uppfylla ett avtal med
                  dig, så som när du ansöker om medlemskap i
                  bostadsrättsföreningen. Om fler uppgifter än de som specifikt
                  behövs för att uppfylla avtalet sparas kommer du bli ombedd
                  att lämna ditt samtycke till det.
                </p>
                <p>
                  <em>Intresseavvägning</em>
                  <br />
                  Personuppgifter kan även sparas för att det ska gå att
                  kommunicera med dig och informera om intressanta tjänster och
                  händelser. I sådant fall sparas uppgifterna utifrån en
                  intresseavvägning. Här krävs att hanteringen av dina
                  personuppgifter anses nödvändig för ett berättigat intresse
                  (att kunna kommunicera med dig) och att ditt intresse av att
                  skydda den typen av personuppgifter inte är av större vikt. Du
                  har alltid rätt att invända mot denna hantering.
                </p>
                <h3>Hur länge persondata sparas</h3>
                <p>
                  Persondata kommer sparas eftersom det utgör en viktig del av
                  verksamhetens nätverk och kundbas, till dess att det inte
                  finns en anledning att spara längre och en rättslig grund som
                  stödjer att det lagras, om personen i fråga inte ber att få
                  sina uppgifter raderade.
                </p>
                <h3>Tillgång till din data</h3>
                <p>
                  Personuppgifter som samlas in behandlas dels av Beteendelabbet
                  i rollen som Personuppgiftsansvarig, men för en fungerande
                  verksamhet förekommer även underleverantörer och
                  samarbetspartners. En sådan part kallas Personuppgiftsbiträde.
                </p>
                <p>
                  Personuppgiftsbiträdet ansvarar för att behandla uppgifterna
                  på ett sätt som följer det avtal som finns upprättat mellan
                  denne och Beteendelabbet.
                </p>
                <p>
                  Om bolaget blir ombedd av en myndighet att lämna ut dina
                  uppgifter är detta en skyldighet.
                </p>
                <p>
                  Om du har lämnat ditt samtycke kan din data lämnas vidare till
                  företag, organisationer eller personer utanför det egna
                  bolaget.
                </p>
                <h3>Så skyddas din data</h3>
                <p>
                  <em>Riskåtgärdsplan</em>
                  <br />
                  Åtgärder som tagits är översyn av system, lösenordshantering,
                  hemsida, plugins osv.
                </p>
                <p>
                  <em>IT säkerhet</em>
                  <br />
                  All persondata hålls samlat i ett lösenordskyddat
                  verksamhetssystem som driftas av Google. Alla lösenord lagras
                  uteslutande i en lösenordshanterare, också för att det är
                  omöjligt att komma ihåg dom annars.
                </p>
                <h3>Dina rättigheter</h3>
                <p>
                  Dataförordningen vill att du på ett klart och tydligt sätt
                  blir informerad om hur din data hanteras, och att den ska
                  finnas lättillgänglig för dig. Du som person har följande
                  rättigheter:
                </p>
                <p>
                  <em>Rätt att bli glömd</em>
                  <br />
                  Du har rätt att utan onödigt dröjsmål få data raderad.
                  Undantag görs för data som måste sparas i enlighet med
                  bokföringslag eller annan lag. Du kan begära att data om dig
                  raderas om någon av följande förutsättningar gäller:
                </p>
                <ul>
                  <li>
                    Om uppgifterna inte längre behövs för de ändamål som de
                    samlats in eller behandlats för.
                  </li>
                  <li>
                    Om behandlingen grundar sig enbart på ditt samtycke och du
                    återkallar detta
                  </li>
                  <li>
                    Om behandlingen sker för direktmarknadsföring och du
                    motsätter dig att uppgifterna behandlas enligt artikel 21.2
                  </li>
                  <li>
                    Om du motsätter dig den databehandling som sker efter en
                    intresseavvägning och det inte finns berättigade skäl som
                    väger tyngre än ditt intresse
                  </li>
                  <li>Om personuppgifterna inte har behandlats enligt lag</li>
                  <li>
                    Om radering krävs för att uppfylla en rättslig skyldighet
                  </li>
                </ul>
                <p>
                  Personuppgifter har samlats in i samband med erbjudande av
                  informationssamhällets tjänster, i de fall som avses i artikel
                  8.1, alltså tjänster som är riktade mot barn, till exempel
                  deras konton i sociala medier.
                </p>
                <p>
                  Om du önskar att radera data och den har lämnat ut den till
                  personuppgiftsbiträden informeras även dessa efter att data
                  har raderats. (Undantag görs om det skulle visa sig omöjligt
                  eller innebära en alltför betungande insats.)
                </p>
                <p>
                  <em>Rätt till begränsning</em>
                  <br />
                  Du har rätt att begära en tillfällig begränsning av behandling
                  av din data. Med begränsning menas att uppgifterna markeras på
                  så sätt att dessa i framtiden endast får behandlas för vissa
                  avgränsade syften. Behandlingen kan begränsas i följande
                  situationer:
                </p>
                <ul>
                  <li>
                    Om data inte är korrekt och du begärt rättelse. Du kan då
                    begära en begränsad behandling av din data under den tid som
                    de korrigeras
                  </li>
                  <li>
                    Om databehandlingen är olaglig men du motsätter dig att din
                    data raderas och istället begär en begränsning av
                    användningen
                  </li>
                  <li>
                    Om din data inte längre behövs för de ändamål på vilket de
                    behandas men du behöver din data för att kunna fastställa,
                    göra gällande eller försvara rättsliga anspråk
                  </li>
                  <li>
                    Om du gjort en invändning mot behandling av din data kan du
                    begära att användningen begränsas under den tid som
                    utredningen pågår
                  </li>
                </ul>
                <p>
                  Om begränsning begärts och din data lämnats ut till
                  personuppgiftsbiträde kommer de informeras om den begärda
                  begränsningen. (Undantag görs om det skulle visa sig omöjligt
                  eller innebära en alltför betungande insats.) Du har alltid
                  rätt att begära information om till vem uppgifterna har
                  lämnats ut.
                </p>
                <p>
                  <em>Rätt att få ut din data (dataportabilitet)</em>
                  <br />
                  Du har rätt att få ut din data så att den kan användas på
                  annat håll. Enligt förordningen ska en sådan förflyttning
                  underlättas och data som överlämnats ska överföras i ett
                  strukturerat, allmänt använt och maskinläsbart format,
                  exempelvis en textfil eller liknande format som är
                  allmängiltigt.
                </p>
                <p>
                  Du kan begära dataportabilitet i de fall behandlingen av din
                  data sker efter samtycke eller på grund av att det finns ett
                  avtal med dig. Däremot gäller inte rätten till
                  dataportabilitet om de behandlas på grund av en
                  intresseavvägning eller rättslig förpliktelse.
                </p>
                <p>
                  <em>Rätt till tillgång</em>
                  <br />
                  Du kan kostnadsfritt (en gång per år) begära ett
                  registerutdrag som visar vilken typ av data som finns
                  registrerad om dig. Informationen ska lämnas ut i skriftlig
                  form och om den begärs ut elektroniskt, måste även kopian av
                  informationen skickas till den registrerade i elektroniskt
                  format, om inte något annat specifik begärs. Informationen som
                  utlämnas ska ha ett tydligt och enkelt språk.
                </p>
                <p>
                  Om persondata kommit till oss via en tredje part eller på
                  annat sätt, har du rätt till all tillgänglig information om
                  varifrån uppgifterna kommer.
                </p>
                <p>
                  Om personuppgifterna ges ut till ett land utanför EU eller en
                  internationell organisation har du rätt till information om
                  vilka skyddsliga åtgärder som använts vid överförandet av
                  data.
                </p>
                <p>
                  Du ska få svar på dina önskemål utan onödigt dröjsmål (normalt
                  inom en månad) och om dessa inte går att uppfylla, ska en
                  motivering lämnas som förklarar varför. För att säkerställa
                  att informationen inte lämnas ut till obehörig lämnas
                  information enbart ut efter identifiering.
                </p>
                <p>
                  <em>Rätt till invändning</em>
                  <br />
                  Om din data behandlas genom en intresseavvägning har du alltid
                  rätt att göra invändningar mot behandlingen. Du behöver då
                  specificera vilken behandling du invänder dig mot och
                  behandling måste då avslutas, om det inte går att visa att det
                  finns berättigade skäl till en fortsatt behandling.
                </p>
                <p>
                  Om din data används för direktmarknadsföring har du alltid
                  rätt att när som helst invända mot behandlingen. Görs en sådan
                  invändning mot direktmarknadsföring, får uppgifterna inte
                  längre behandlas för det ändamålet.
                </p>
                <p>
                  Du har även rätt att sätta dig emot användning om det skulle
                  grunda sig på allmänutövning eller myndighetsintresse om det
                  inte går att bevisa hur syftet att hålla uppgifterna väger
                  tyngre.
                </p>
                <p>
                  <em>Rätt till rättelse och komplettering</em>
                  <br />
                  Du har rätt att få din data rättad och kompletterad med
                  relevanta uppgifter om du anser att den är felaktig eller
                  otillräcklig. Även eventuella personuppgiftsbiträden kommer då
                  informeras. (Undantag görs om det skulle visa sig omöjligt
                  eller innebära en alltför betungande insats.) Du har alltid
                  rätt att begära information om till vem uppgifterna har
                  lämnats ut.
                </p>
                <h3>Klagomål</h3>
                <p>
                  Om du skulle anse att behandlingen av din data är i strid med
                  gällande regelverk, vänligen ta kontakt så det går att rätta
                  till. Du kan också lämna in ett klagomål till Datainspektionen
                  som då bedömer om en tillsyn ska göras.
                </p>
                <h3>Skadestånd</h3>
                <p>
                  Om du lidit skada till följd av att behandling av din data
                  står i strid med dataskyddsförordningen kan du ha rätt till
                  skadestånd från den personuppgiftsansvariga eller från
                  personuppgiftsbiträdet som medverkat vid behandlingen.
                </p>
                <p>
                  Ett personuppgiftsbiträde kan bli skadeståndsansvarig om denne
                  har brutit mot de bestämmelser som specifikt riktar sig till
                  biträden eller har behandlat dina data i strid med den
                  personuppgiftsansvariges instruktioner i det skriva avtalet.
                  Du kan även väcka skadeståndstalan i domstol.
                </p>
                <h3>Kontaktuppgifter</h3>
                <p>
                  Om du vill radera data, ändra data, komplettera data, göra en
                  invändning hur din data hanteras eller bara har frågor eller
                  funderingar kring behandlingen går det alltid bra att ta
                  kontakt:
                </p>
                <p>
                  Namn: Ida Lemoine
                  <br />
                  Mail: info@beteendelabbet.se
                </p>
              </div>
            </article>
          </div>
        </section>
      </ContentWrapper>
    </AppChrome>
  );
}

export default Policy;
